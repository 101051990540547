<template>
  <div class="publish-container">
    <!-- 导航栏 -->
    <van-nav-bar
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Bình luận</span
        >
      </template>
      <van-button
        slot="right"
        type="warning"
        :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
        @click="clickPublic"
        >Đăng bài</van-button
      >
    </van-nav-bar>
    <van-field
      v-model="publishContent"
      rows="1"
      autosize
      type="textarea"
      placeholder="nhận xét..."
    />
    <van-uploader :after-read="afterRead" v-model="fileList" multiple />
  </div>
</template>

<script>
import { userPublish, Publishtheam } from '@/api/comments.js'
export default {
  name: 'DiscoverPublish',
  components: {},
  data() {
    return {
      publishContent: '',
      fileList: [],
      attach: [],
      userInfo: JSON.parse(window.localStorage.userInfo),
    }
  },
  mounted() {
    this.getThemeList()
  },
  created() {},
  methods: {
    //   点击发布
    async clickPublic() {
      const {
        data: { ret, msg, data },
      } = await userPublish({
        content: this.publishContent,
        attach: JSON.stringify(this.attach),
        activity_id: this.userInfo.id,
      })
      if (ret === 1) {
        this.$toast(msg)
        this.$router.back()
      }
    },
    // 点击上传图片
    afterRead(file) {
      console.log(file)
      this.attach.push(file)
    },

    // 主题
    async getThemeList() {
      const res = await Publishtheam()
      console.log(res)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-button__content {
  // color: #fff;
}
.publish-container {
  .van-nav-bar {
    width: 100%;
    height: 100px;
    position: fixed;
  }
  /deep/ .van-nav-bar__title {
    // color: #fff;
  }
  /deep/ .van-nav-bar__content {
    // background: #ff0000;
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
  .van-button {
    width: 150px;
    height: 60px;
    background-color: #ef7a0a;
    border: 0;
    color: #ff0000;
  }
  .van-field {
    padding-top: 100px;
    background: #f4f8f9;
  }

  .van-uploader {
    width: 100%;
    height: 100%;
    margin-top: 200px;
    /deep/ .van-uploader__wrapper {
      float: left;
      width: 100%;
      height: 250px;
    }
    /deep/ .van-uploader__preview {
      margin: 0;
      width: 250px;
      height: 250px;
    }
    /deep/ .van-uploader__preview-image {
      width: 250px;
      height: 250px;
    }
    /deep/ .van-uploader__upload {
      margin: 0;
      width: 250px;
      height: 250px;
      background: #fff;
    }
    /deep/ .van-uploader__input {
      width: 250px;
      height: 250px;
    }
  }
}
</style>
